import type { CentraVoucher } from "@frend-digital/centra/schemas/selection/utilitySchemas";

import type { PDPProduct } from "@/app/[locale]/(static)/product/[product]/page";
import type { CartItem } from "@/lib/hooks/useSelection/useSelection";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const pageview = (url: string) => {
  window?.dataLayer?.push({
    config: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
    page_path: url,
  });
};

interface GTagEvent {
  action: string;
  category: string;
  label: string;
  value: number;
}

export const event = ({ action, category, label, value }: GTagEvent) => {
  window?.dataLayer?.push({
    event: action,
    event_category: category,
    event_label: label,
    revenue: value,
  });
};

export const generateGTagItem = (
  item: PDPProduct,
  price?: number,
  quantity: number = 1,
) => {
  const item_id = item?.sku || "";
  const item_name = item?.name || "";
  const item_category = item?.breadcrumbs?.[0]?.name || "";
  const item_variant = item?.variantName || "";
  const item_price = price || NaN;
  const item_quantity = quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export const convertCartToGTagItem = (item: CartItem) => {
  const item_id = item?.sku || "";
  const item_name = item?.name || "";
  const item_category = item?.category || "";
  const item_variant = item?.variant || "";
  const item_price = item.priceAsNumber || NaN;
  const item_quantity = item.quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export interface GTagItem {
  item_id: string; // SKU
  item_name: string; // Product Name
  discount?: number;
  item_category: string; // Top Category /women
  item_category2?: string; // Sub Category /women/!dresses!
  item_category3?: string;
  item_variant: string; // Variant name
  price: number; // Price
  quantity?: number; // How many
}
interface EventCartProps {
  currency: string;
  value?: number;
  items: GTagItem[] | [];
}

export const eventAddToCart = ({ currency, value, items }: EventCartProps) => {
  window?.dataLayer?.push({
    event: "add_to_cart",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      items: items,
    },
  });
};

export const eventProductDetail = ({ currency, items }: EventCartProps) => {
  window?.dataLayer?.push({
    event: "view_item",
    ecommerce: {
      currencyCode: currency,
      items,
    },
  });
};

export const eventViewCart = ({ currency, value, items }: EventCartProps) => {
  window?.dataLayer?.push({
    event: "view_cart",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      items: items,
    },
  });
};

export const eventBeginCheckout = ({
  currency,
  value,
  items,
}: EventCartProps) => {
  window?.dataLayer?.push({
    event: "begin_checkout",
    ecommerce: {
      currencyCode: currency,
      revenue: value,
      items: items,
    },
  });
};

interface EventPurchaseProps {
  transaction_id: string;
  tax: number;
  shipping: number;
  coupon: string | CentraVoucher;
  currency: string;
  value: number;
  items: GTagItem[];
}

export const eventPurchase = ({
  transaction_id,
  tax,
  shipping,
  coupon,
  currency,
  value,
  items,
}: EventPurchaseProps) => {
  window?.dataLayer?.push({
    event: "purchase",
    ecommerce: {
      affiliation: "Online Store",
      id: transaction_id,
      tax,
      shipping,
      coupon,
      currencyCode: currency,
      revenue: value,
      items: items,
    },
  });
};

import type { FormEvent } from "react";
import { useEffect, useState } from "react";

import { useSelection } from "@/lib/hooks/useSelection";
import { usePromoMutation } from "@/lib/hooks/useSelection/cart/usePromo";

const useVoucher = () => {
  const { data } = useSelection();
  const vouchers = data?.selection?.discounts;
  const [code, setCode] = useState("");
  const [activeInput, setActiveInput] = useState(false);
  const [hasPromo, setHasPromo] = useState(false);
  const [error, setError] = useState("");
  const { promo } = usePromoMutation();
  useEffect(() => {
    if (!vouchers?.anyDiscount) {
      setHasPromo(false);
      setActiveInput(false);
      setCode("");
    }
  }, [vouchers]);

  useEffect(() => {
    if (promo.isError) {
      setError(promo.error.message);
    } else if (
      vouchers?.anyDiscount &&
      !promo.isPending &&
      vouchers.vouchers?.length
    ) {
      setCode(vouchers?.vouchers?.[0]?.voucher || "");
      setHasPromo(true);
      setError("");
      setActiveInput(false);
    }
  }, [promo, vouchers]);

  const add = (e: FormEvent) => {
    e.preventDefault();
    promo.mutate({
      code,
      remove: false,
    });
  };

  const remove = () => {
    promo.mutate({
      code: vouchers?.vouchers?.[0]?.voucher || "",
      remove: true,
    });
  };

  return {
    add,
    remove,
    activateInput: () => {
      setActiveInput(true);
    },
    deactivateInput: () => setActiveInput(false),
    isLoading: promo.isPending,
    showInput: activeInput && !hasPromo,
    showRemove: hasPromo && vouchers?.vouchers?.length,
    error: error !== "" ? error : null,
    isValid: !promo.isError && !promo.isPending,
    code: code,
    amount: vouchers?.discount,
    updateCode: (e: React.ChangeEvent<HTMLInputElement>) =>
      setCode(e.target.value),
  };
};

export default useVoucher;
